import React from 'react';
import {
  Hero,
  Module,
  HalfAndHalf,
  TextLockup,
  Layout,
  Text,
  Faq,
  Button
} from '../redesign';
import heroContent from '../redesign/content/insights/insights-hero.json';
import healthHistoryContent from '../redesign/content/insights/knowing-health-history.json';
import wholeBodyContent from '../redesign/content/insights/introduction-whole-body.json';
import personalWellnessContent from '../redesign/content/insights/personalised-wellness-plan.json';
import trackYourProgressContent from '../redesign/content/insights/track-your-progress.json';
import faqs from '../redesign/content/insights/faqs';
import { default as SEO } from '../components/SEO';

export default function Inights() {
  return (
    <Layout navbarType="INSIGHTS" footerType="INSIGHTS">
      <SEO
        pageTitle="Insights by Parsley Health"
        pageDescription="Parsley Health's functional medicine approach uses advanced testing and personalized health plans to get to the root cause of symptoms. Our holistic medicine delivers transformational results."
      />
      <Module>
        <Hero {...heroContent} />
      </Module>

      <Module>
        <HalfAndHalf
          image={healthHistoryContent.image}
          imagePosition={healthHistoryContent.imagePosition}
          fullImage
        >
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-md">
              {healthHistoryContent.headline}
            </Text>
            <Text variant="p2a">{healthHistoryContent.description}</Text>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <HalfAndHalf
          image={wholeBodyContent.image}
          imagePosition={wholeBodyContent.imagePosition}
          reversed={[false, true]}
          fullImage
        >
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-md">
              {wholeBodyContent.headline}
            </Text>
            <Text variant="p2a">{wholeBodyContent.description}</Text>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <HalfAndHalf
          image={personalWellnessContent.image}
          imagePosition={personalWellnessContent.imagePosition}
          fullImage
        >
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-md">
              {personalWellnessContent.headline}
            </Text>
            <Text variant="p2a">{personalWellnessContent.description}</Text>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <HalfAndHalf
          image={trackYourProgressContent.image}
          imagePosition={trackYourProgressContent.imagePosition}
          reversed={[false, true]}
          fullImage
        >
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-md">
              {trackYourProgressContent.headline}
            </Text>
            <Text variant="p2a">{trackYourProgressContent.description}</Text>

            <div className="text-lockup-buttons-container">
              {trackYourProgressContent.cta && (
                <Button
                  color="sage"
                  destination={trackYourProgressContent.cta.destination}
                  className="mt-md"
                  trackingLabel={trackYourProgressContent.cta.ctaTrackingLabel}
                >
                  {trackYourProgressContent.cta.label}
                </Button>
              )}
            </div>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <Faq faqs={faqs.questions} heading={faqs.headline} />
      </Module>
    </Layout>
  );
}
