import React from 'react';
import { Text, Link } from '../../';

const textVariant = 'p2a';

const containerClassName = 'mb-sm';

const subTitleClassName = 'mb-tiny';

export default {
  headline: 'Frequently asked questions?',
  questions: [
    {
      id: '1',
      question: 'What does my subscription include?',
      answer: (
        <div className={containerClassName}>
          <Text variant={textVariant} className={subTitleClassName}>
            Insights by Parsley Health is a digital tool and general wellness
            product intended to help you take an active role in maintaining a
            healthy lifestyle. Your subscription includes personalized challenge
            recommendations, a digital routine builder, and an option to receive
            daily check-in text messages. Your subscription does not include
            access to a doctor or a health coach. While Insights by Parsley
            Health was developed in collaboration with Parsley Health's clinical
            team, clinicians and health coaches do not review each personalized
            wellness plan. <br />
            If you're interested in working with one of our clinicians, check
            out our{' '}
            <Link
              underline
              variant={textVariant}
              destination="https://www.parsleyhealth.com/join/"
            >
              doctor-led membership program.
            </Link>
          </Text>
        </div>
      )
    },
    {
      id: '2',
      question: 'What if I change my mind about my subscription?',
      answer: (
        <div className={containerClassName}>
          <Text variant={textVariant} className={subTitleClassName}>
            You can cancel your subscription at any time! Log in to your account
            and click on the Manage Subscription option near the bottom of your
            Account page. Then, click the Cancel Plan button.
          </Text>
        </div>
      )
    },
    {
      id: '3',
      question: 'Will I get access to a doctor or health coach?',
      answer: (
        <div className={containerClassName}>
          <Text variant={textVariant}>
            While Insights by Parsley Health was developed in collaboration with
            Parsley Health's clinical team, clinicians and health coaches do not
            review each personalized wellness plan. Your subscription does not
            include any access, information, or interaction with Parsley
            Health's doctors or health coaches. If you're interested in working
            with one of our clinicians, check out our{' '}
            <Link
              underline
              variant={textVariant}
              destination="https://www.parsleyhealth.com/join/"
            >
              doctor-led membership program.
            </Link>
          </Text>
        </div>
      )
    }
  ]
};
